@mixin desktop {
  @media only screen and (min-width: 1025px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@keyframes plcAnimationSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingComponent {
  background-color: white;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;

  > .main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 800px) {
      height: auto;
    }

    > .container {
      width: 100%;
      position: relative;
      align-items: center;
      justify-content: center;
      display: flex;
      
      @media (max-width: 800px) {
        width: 100%;
      }

      > .loaderGif {
        width: 200px;
        height: 94px;
      }

      > svg {
        grid-column: 1;
        grid-row: 1;
      }

      .plc-loader-spiner {
        animation: plcAnimationSpin 1s infinite;
      }

      .scale-loader {
        transform: scale(0.56);
        -ms-transform: scale(0.56);
        -webkit-transform: scale(0.56);
      }

      .plc-loader-logo {
        position: absolute;
        top: 50%;
        margin-top: -50px;
      }

      > img {
        width: 20%;

        @media (max-width: 800px) {
          width: 80%;
        }
      }
    }

    .plcSafeSite {
      > a > img {
        width: 83px;
        height: 23px;
      }
    }

    > div.plc-safe-site {
      position: absolute;
      bottom: 0;
      margin-bottom: 2rem;
      
      :hover {
        opacity: 0.7;
      }
    }

    .plc-wait-2 {
      display: none;
      text-align: center;
    }

    .plc-wait {
      padding-bottom: 16px;

      font-size: 2rem;
      text-align: center;
      text-decoration: underline;
      text-decoration-color: #00e169;
      font-weight: 600;
      line-height: 130%;
    }

    > p {
      text-align: center;
      margin: 0;

      &.plc-txt {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        background: #cafdce;
        border: 1px solid #00c16d;
        box-sizing: border-box;
        border-radius: 16px;

        padding: 24px 32px;
        @include mobile {
          padding: 24px;
        }

        > span {
          text-align: center;
          font-size: 18px;

          > b {
            font-weight: bold;
            padding: 0 3px;
          }
        }
      }
    }
  }
}